import {
    API_GET_ALL_ORDER, API_GET_ALL_PROMOCODE, API_GET_ORDER_INFO, API_PLACE_ORDER, API_VERIFY_PROMOCDE,
    API_APPLY_PROMOCDE,
    API_REMOVE_PROMOCDE,
    API_RETURN_ORDER,
    API_CANCEL_ORDER,
    API_PAY_ONLINE_ORDER,
    API_GET_ALL_FEEDBACK_USER,
    API_ADD_FEEDBACK,
    API_ALL_FAQ_CATEGORY,
    API_GET_FAQ_BY_CATEGORY,
    API_NOTIFICATION_LIST,
    API_READ_NOTIFICATION,
    API_DELETE_NOTIFICATION,
    API_NOTIFICATION_COUNT,
    API_GET_FAQ_LIST,
    API_VERIFY_TOKEN,
    API_COMPLETE_COD_PAYMENT,
    API_DOWNLOAD_INVOICE,
    API_GET_PROMO_CODE_USED,
    API_GET_CUSTOMER_ORDER_COUNT,
    API_CHECK_AND_GET_REFERRAL,
    API_CHECK_REFERRAL_CODE,
    API_GET_REFERRAL_DATA,
    API_GET_REFERRAL_COUPON_DATA,
    API_PRODUCT_STAR_RATING,
    API_ADD_PRODUCT_REVIEW,
    API_LIST_PRODUCT_RATING,
    API_SHOW_LIST_PRODUCT_REVIEW
    

} from "../../constants/api";
import {
    API, GET_ALL_PROMOCODE_ERROR, GET_ALL_PROMOCODE_SUCCESS, GET_CUSTOMER_FEEDBACK_ERROR, GET_CUSTOMER_FEEDBACK_SUCCESS, GET_FAQ_BY_CATEGORY_ERROR, GET_FAQ_BY_CATEGORY_SUCCESS, GET_FEEDBACK_CATEGORY_ERROR, GET_FEEDBACK_CATEGORY_SUCCESS, GET_MY_ORDER_INFO_ERROR, GET_MY_ORDER_INFO_SUCCESS, GET_MY_ORDER_LIST_ERROR, GET_MY_ORDER_LIST_SUCCESS, GET_NOTIFICATION_COUNT_ERROR, GET_NOTIFICATION_COUNT_SUCCESS, GET_NOTIFICATION_LIST_ERROR,
    GET_NOTIFICATION_LIST_SUCCESS,
    CHECK_AND_GET_REFERRAL_SUCCESS,
    CHECK_AND_GET_REFERRAL_ERROR,
    CHECK_REFERRAL_CODE_SUCCESS,
    CHECK_REFERRAL_CODE_ERROR,
    ADD_AND_REMOVE_REFERRAL_CODE,
    API_GET_REFERRAL_DATA_SUCCESS,
    API_GET_REFERRAL_DATA_ERROR,
    API_GET_REFERRAL_COUPON_DATA_SUCCESS,
    API_GET_REFERRAL_COUPON_DATA_ERROR,
    API_PRODUCT_STAR_RATING_S,
    API_PRODUCT_STAR_RATING_F,
    API_ADD_PRODUCT_REVIEW_S,
    API_ADD_PRODUCT_REVIEW_F,
    API_LIST_PRODUCT_RATING_S,
    API_LIST_PRODUCT_RATING_F,
    API_SHOW_LIST_PRODUCT_REVIEW_S,
    API_SHOW_LIST_PRODUCT_REVIEW_F
} from "../../constants/types";

// Get all Promocode list
export const getAllPromoCode = (data) => ({
    type: API,
    payload: {
        // url: `${API_GET_ALL_PROMOCODE}?search=${data ? data : ''}`,
        url: API_GET_ALL_PROMOCODE,
        // method: 'GET',
        method: 'POST',
        data: data,
        success: (data) => ({
            type: GET_ALL_PROMOCODE_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_ALL_PROMOCODE_ERROR,
            payload: data
        })
    }
})
// Promocode verify
export const onPromocodeVerify = (data) => ({
    type: API,
    payload: {
        url: API_VERIFY_PROMOCDE,
        method: 'POST',
        data: data,
    }
})

// Promocode Apply
export const onPromocodeApply = (data) => ({
    type: API,
    payload: {
        url: API_APPLY_PROMOCDE,
        method: 'POST',
        data: data,
    }
})

// Promocode Remove
export const onPromocodeRemove = (data) => ({
    type: API,
    payload: {
        url: API_REMOVE_PROMOCDE,
        method: 'POST',
        data: data,
    }
})

// Get all Order list
export const getAllOrder = (data) => ({
    type: API,
    payload: {
        url: `${API_GET_ALL_ORDER}?sort=${data?.sort ? data.sort : "DESC"}&filter=${data?.filter ? data.filter : "all"}`,
        // url: API_GET_ALL_ORDER,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_MY_ORDER_LIST_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_MY_ORDER_LIST_ERROR,
            payload: data
        })
    }
})

// Get Order Info
export const getOrderInfo = (data) => ({
    type: API,
    payload: {
        url: API_GET_ORDER_INFO + `?id=${data}`,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_MY_ORDER_INFO_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_MY_ORDER_INFO_ERROR,
            payload: data
        })
    }
})

// Get Order Info
export const onPayOnline = (data) => ({
    type: API,
    payload: {
        url: API_PAY_ONLINE_ORDER,
        method: 'POST',
        data: data,
    }
})

// Get Order Info
export const onPlaceOrderApi = (data) => ({
    type: API,
    payload: {
        url: API_PLACE_ORDER,
        method: 'POST',
        data: data,
    }
})

// To Return Order
export const onReturnOrder = (data) => ({
    type: API,
    payload: {
        url: API_RETURN_ORDER,
        method: 'POST',
        data: data,
    }
})

// To Return Order
export const onCancelOrder = (data) => ({
    type: API,
    payload: {
        url: API_CANCEL_ORDER,
        method: 'POST',
        data: data,
    }
})

// HELP CENTER


// Get All Feedbakcs
export const getAllFeedbackCategory = (data) => ({
    type: API,
    payload: {
        url: API_ALL_FAQ_CATEGORY,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_FEEDBACK_CATEGORY_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_FEEDBACK_CATEGORY_ERROR,
            payload: data
        })
    }
})

// Get All FAAQ
export const getAllFaqList = (data) => ({
    type: API,
    payload: {
        url: API_GET_FAQ_LIST,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_FAQ_BY_CATEGORY_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_FAQ_BY_CATEGORY_ERROR,
            payload: data
        })
    }
})

// Get All Feedback by category
export const getAllFaqByCategory = (data) => ({
    type: API,
    payload: {
        url: API_GET_FAQ_BY_CATEGORY + `?id=${data}`,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_FAQ_BY_CATEGORY_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_FAQ_BY_CATEGORY_ERROR,
            payload: data
        })
    }
})

// Get All Feedbacks of User
export const getAllFeedBackOfUser = (data) => ({
    type: API,
    payload: {
        url: API_GET_ALL_FEEDBACK_USER,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_CUSTOMER_FEEDBACK_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_CUSTOMER_FEEDBACK_ERROR,
            payload: data
        })
    }
})

// To Add FAQ
export const toAddFaq = (data) => ({
    type: API,
    payload: {
        url: API_ADD_FEEDBACK,
        method: 'POST',
        data: data,
    }
})

// NOTIFICATIONS

// Get All Notification
export const getNotificationList = (data) => ({
    type: API,
    payload: {
        url: API_NOTIFICATION_LIST,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: GET_NOTIFICATION_LIST_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_NOTIFICATION_LIST_ERROR,
            payload: data
        })
    }
})

// Get All Notification
export const getNotificationCount = (data) => ({
    type: API,
    payload: {
        url: API_NOTIFICATION_COUNT,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: GET_NOTIFICATION_COUNT_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: GET_NOTIFICATION_COUNT_ERROR,
            payload: data
        })
    }
})

// To Read Notification
export const toReadNotification = (data) => ({
    type: API,
    payload: {
        url: API_READ_NOTIFICATION,
        method: 'POST',
        data: data,
    }
})

// To Delete Notification
export const toDeleteNotification = (data) => ({
    type: API,
    payload: {
        url: API_DELETE_NOTIFICATION,
        method: 'POST',
        data: data,
    }
})

// To check token is valid or not
export const toCheckTokenValid = (orderId, token) => ({
    type: API,
    payload: {
        url: `${API_VERIFY_TOKEN}?orderId=${orderId}&token=${token}`,
        method: 'GET',
    }
})

// To complete cod payment  
export const toCompleteCodPayment = (data) => ({
    type: API,
    payload: {
        url: API_COMPLETE_COD_PAYMENT,
        method: 'POST',
        data: data,
    }
})

// To download invoice  
export const toGetInvoice = (data) => ({
    type: API,
    payload: {
        url: API_DOWNLOAD_INVOICE,
        method: 'POST',
        data: data,
    }
})
//Abhishek Srivastava
export const getPromocodeUsedCustomer = (data) => ({
    type: API,
    payload: {
        url: API_GET_PROMO_CODE_USED,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: "promocode Applied Check Success",
            payload: data
        }),
        error: (data) => ({
            type: "promocode Applied Check Error",
            payload: data
        })
    }
})
//Abhishek Srivastava 30/04/2024
export const getCustomerOrderCount = (data) => ({
    type: API,
    payload: {
        url: API_GET_CUSTOMER_ORDER_COUNT,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: "Get customer order count success",
            payload: data
        }),
        error: (data) => ({
            type: "Get customer order count error",
            payload: data
        })
    }
})
export const checkAndGetReferral = (data) => ({
    type: API,
    payload: {
        url: API_CHECK_AND_GET_REFERRAL,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: CHECK_AND_GET_REFERRAL_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: CHECK_AND_GET_REFERRAL_ERROR,
            payload: data
        })
    }
})

export const addOrGetReferralDiscount = (data) => ({
    type: ADD_AND_REMOVE_REFERRAL_CODE,
    payload: {
        referralDiscount: data.referralDiscount,
        isAppliedReferralDiscount: data.isAppliedReferralDiscount
    }
})

export const checkReferralCode = (data) => ({
    type: API,
    payload: {
        url: API_CHECK_REFERRAL_CODE,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: CHECK_REFERRAL_CODE_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: CHECK_REFERRAL_CODE_ERROR,
            payload: data
        })
    }
})

export const getReferralData = (data) => ({
    type: API,
    payload: {
        url: API_GET_REFERRAL_DATA,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: API_GET_REFERRAL_DATA_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: API_GET_REFERRAL_DATA_ERROR,
            payload: data
        })
    }
})

export const getReferralCouponData = (data) => ({
    type: API,
    payload: {
        url: API_GET_REFERRAL_COUPON_DATA,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: API_GET_REFERRAL_COUPON_DATA_SUCCESS,
            payload: data
        }),
        error: (data) => ({
            type: API_GET_REFERRAL_COUPON_DATA_ERROR,
            payload: data
        })
    }
})
export const productStarRating = (data) => ({
    type: API,
    payload: {
        url: API_PRODUCT_STAR_RATING,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: API_PRODUCT_STAR_RATING_S,
            payload: data
        }),
        error: (data) => ({
            type: API_PRODUCT_STAR_RATING_F,
            payload: data
        })
    }
})
export const addProductReview = (data) => ({
    type: API,
    payload: {
        url: API_ADD_PRODUCT_REVIEW,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: API_ADD_PRODUCT_REVIEW_S,
            payload: data
        }),
        error: (data) => ({
            type: API_ADD_PRODUCT_REVIEW_F,
            payload: data
        })
    }
})

export const getProductListRating = (data) => ({
    type: API,
    payload: {
        url: API_LIST_PRODUCT_RATING,
        method: 'POST',
        data: data,
        success: (data) => ({
            type: API_LIST_PRODUCT_RATING_S,
            payload: data
        }),
        error: (data) => ({
            type: API_LIST_PRODUCT_RATING_F,
            payload: data
        })
    }
})

export const showListProductReview = (data) => ({
    type: API,
    payload: {
        url: API_SHOW_LIST_PRODUCT_REVIEW,
        method: 'GET',
        data: data,
        success: (data) => ({
            type: API_SHOW_LIST_PRODUCT_REVIEW_S,
            payload: data
        }),
        error: (data) => ({
            type: API_SHOW_LIST_PRODUCT_REVIEW_F,
            payload: data
        })
    }
})