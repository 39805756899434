import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../../assets/svg/logo';
import Cart from '../../../assets/svg/cart';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'
import { CgSearch } from "react-icons/cg";
import { RiArrowDownSLine } from "react-icons/ri";
import Iconlocation from '../../../assets/svg/location-icon';
import ProductImg from '../../../assets/svg/products_img';
import OutSideClick from '../../../utils/OutSideClick'
import { useDispatch, useSelector } from 'react-redux';
import Clefill from "../../../assets/svg/footer-logo-with-cap";
import { GET_CART_COUNT_LOCAL_ERROR, GET_CART_COUNT_LOCAL_SUCCESS, GET_CART_COUNT_SUCCESS, VALID_LOCATION_SUCCESS } from '../../../constants/types';
import { checkLocation, createAcc, doLogout, getProfile, onSignIn, sendOTP, verifyOTP } from '../../../redux/actions/authAction';
import Close from '../../../assets/svg/close';
import BackArrow from '../../../assets/svg/backArrow';
import { MdOutlineNotificationsNone } from 'react-icons/md';
import { AiOutlineUser } from 'react-icons/ai';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import { addCartLocalToApi, getCart, getCartCount, productList, visitorCall } from '../../../redux/actions/productAction';
import { Field, Form } from 'react-final-form';
import { renderInput, renderNumberInput } from '../../../utils/FormField';
import { SigninValidate, CreateAcc } from '../../../utils/validate';
import { createDeviceId, displayPhoneNumber, getDeviceType, pincodeFromLatLng, removeCountryCode, removeMobileFomrmatter, removeQuotsFun, toAbsoluteUrl } from '../../../utils';
import OtpInput from 'react-otp-input';
import LoaderBox from './Loader';
import axios from 'axios';

const Header = ({ signinModal, setsigninModal }) => {

    const dropDownRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const isValidLocation = useSelector(state => state.auth.isValidLocation)
    const cartCount = useSelector(state => state.product.cartCount)
    const cartCountLocal = useSelector(state => state.product.cartCountLocal)
    const profileData = useSelector(state => state.auth.userProfile?.profile)
    const userData = useSelector(state => state.auth.userData)
    const notificationCount = useSelector(state => state.userUtils.notificationCount)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const authData = useSelector(state => state.auth)
    const allProductList = useSelector(state => state.product.productList)

    const [small, setSmall] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [showDropDown, setshowDropDown] = useState(false);
    const [otpModal, setotpModal] = useState(false);
    const [signupModal, setsignupModal] = useState(false);
    const [locationModal, setLocationModal] = useState(false);
    const [otpData, setotpData] = useState({});
    const [suggestions, setSuggestions] = useState([]);
    // const [isAgreed, setisAgreed] = useState(false)
    const [Timer, setTimer] = useState(0);
    const [otpValue, setotpValue] = useState('');
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
    const deviceIp = JSON.parse(localStorage.getItem("deviceIp"));
    const sessionDeviceIp = JSON.parse(sessionStorage.getItem("deviceIp"));

    const [keyword, setKeyword] = React.useState('')

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setSmall(window.pageYOffset > 0)
            );
        }
    }, []);

    useEffect(() => {
        setKeyword("")
        setSuggestions([])
    }, [window.location.pathname]);

    // Set countdown for otp modal
    useEffect(() => {
        setTimeout(() => {
            if (Timer > 0 && otpModal) {
                setTimer(Timer - 1)
            }
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Timer, otpModal])
    // Header dropdown outside click Fun
    OutSideClick(dropDownRef, () => {
        if (showDropDown) { setshowDropDown(false); }
    });
    // Fun to get cart data for loggedin and non loggedin also fetch cart total and profile api
    useEffect(() => {
        // if (authData?.isValidLocation) {
        //     dispatch(getNameFrmPincode(authData?.validLocation))
        // }
        if (isLoggedIn) {
            dispatch(getCartCount({ customerId: userData?.id }))
            dispatch(getProfile())
        }
        else {
            if (JSON.parse(localStorage.getItem('cartTotal'))) {
                let length = JSON.parse(localStorage.getItem('cartTotal'))
                dispatch({
                    type: GET_CART_COUNT_LOCAL_SUCCESS, payload: length
                })
            }
            else {
                dispatch({
                    type: GET_CART_COUNT_LOCAL_ERROR, payload: 0
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    // Logout function
    const onLogout = () => {

        const fcmToken = JSON.parse(localStorage.getItem("FCMToken"));
        dispatch(doLogout({ fcmToken: fcmToken ? fcmToken : ' ' }))

        dispatch({ type: GET_CART_COUNT_SUCCESS, payload: 0 })
        if (localStorage.getItem('cart')) {
            localStorage.removeItem('cart')
        }
        if (localStorage.getItem('cartTotal')) {
            localStorage.removeItem('cartTotal')
        }
    };

    const handleVisitorCall = () => {
        if (!sessionDeviceIp && deviceIp) {
            dispatch(
              visitorCall({
                ipAddress: deviceIp,
              })
            );
            sessionStorage.setItem("deviceIp", JSON.stringify(deviceIp));
          }
    }
    //For Location pop up detect location api
    const onDetectLocation = (manualPinCode, type) => {

        let tmpIp = JSON.parse(localStorage.getItem("deviceIp"));
        let tmpObj = { ipAddress: tmpIp, customerId: userData?.id ? userData?.id : '' }

        if (type === 'manual') {
            let manualData = { pincode: manualPinCode, ...tmpObj }
            setisLoading(true)
            dispatch(checkLocation(manualData))
                .then(res => {
                    setisLoading(false)
                    setLocationModal(false)
                    axios.defaults.headers.common["pincode"] = res.data?.pincode
                    localStorage.setItem('pincode', res.data?.pincode)
                    handleVisitorCall()
                    localStorage.setItem('validLocation', JSON.stringify(res.data?.pincode + ' ' + res.data?.area?.city))
                    setTimeout(() => {
                        dispatch({ type: VALID_LOCATION_SUCCESS, payload: JSON.stringify(res.data?.pincode + ' ' + res.data?.area?.city) })
                    }, 1000);
                    dispatch(productList({
                        page: 1,
                        limit: 1000,
                        customerId: '',
                        productType: ""
                    }))
                })
                .catch(err => {
                    setisLoading(false)
                    swal('', err.message, 'error')
                })
        }
        else {
            //success function
            setisLoading(true)
            navigator.geolocation.getCurrentPosition((pos) => {
                var crd = pos.coords

                pincodeFromLatLng(crd.latitude, crd.longitude)
                    .then(pincodeRes => {
                        dispatch(checkLocation({ pincode: pincodeRes, ...tmpObj }))
                            .then(res => {
                                setisLoading(false)
                                setLocationModal(false)
                                axios.defaults.headers.common["pincode"] = res.data?.pincode
                                localStorage.setItem('pincode', res.data?.pincode)
                                handleVisitorCall()
                                localStorage.setItem('validLocation', JSON.stringify(res.data?.pincode + ' ' + res.data?.area?.city))
                                setTimeout(() => {
                                    dispatch({ type: VALID_LOCATION_SUCCESS, payload: JSON.stringify(res.data?.pincode + ' ' + res.data?.area?.city) })
                                }, 1000);
                                dispatch(productList({
                                    page: 1,
                                    limit: 1000,
                                    customerId: '',
                                    productType: ""
                                }))
                            })
                            .catch(err => {
                                setisLoading(false)
                                swal(`Detected pincode : ${pincodeRes}`, err.message, 'error')
                            })
                    })
                    .catch(err => {
                        setisLoading(false)
                        console.log('err: ', err);
                    })

            }, (err) => {
                // error function
                setisLoading(false)
                console.log('err: ', err);
                if (err?.message) {
                    if (err?.code === 1)
                        swal('Location Permission Required', 'Please allow location permission !', 'error')
                    else if (err?.code === 2)
                        swal('Position unavailable !', 'Please try again or try by adding pincode manually !', 'error')
                    else if (err?.code === 3)
                        swal('Timed Out !', 'Please try again or try by adding pincode manually !', 'error')
                    else {
                        swal('', err?.message, 'error')
                    }
                }
                else {
                    alert('something went wrong')
                }
            }, {
                enableHighAccuracy: true,

                timeout: 5000,
                maximumAge: 0
            });
        }
    };
    // On Signup Submit 
    const onSignUpSubmit = (data, form) => {
        dispatch(sendOTP({ phoneNumber: removeMobileFomrmatter(data.phoneNumber), type: 'register' }))
            .then(res => {
                setotpData({ ...data, phoneNumber: removeMobileFomrmatter(data.phoneNumber), type: 'register' })
                setsignupModal(false)
                setTimer(60)
                setotpModal(true)
                // setisAgreed(false)
                form?.restart()
            })
            .catch(err => {
                swal('', err.message, 'error')
                // resetOtpData()
            })
    };
    // On signin Submit
    const onSignInSubmit = (data, form) => {
        dispatch(sendOTP({ phoneNumber: removeMobileFomrmatter(data.phoneNumber), type: 'login' }))
            .then(res => {
                setotpData({ ...data, phoneNumber: removeMobileFomrmatter(data.phoneNumber), type: 'login' })
                setsigninModal(false)
                setTimer(60)
                setotpModal(true)
                form?.restart()
            })
            .catch(err => {
                form?.restart()
                setsigninModal(false)
                setotpData({ ...otpData, phoneNumber: removeMobileFomrmatter(data.phoneNumber) })
                setsignupModal(true)
                setTimer(60)
                // swal('', err.message, 'error')
                // resetOtpData()
            })
    };
    // Set otp value to local state
    const onOtpChange = (otp) => {
        setotpValue(otp)
    };
    // Reset otp data on modal close
    const resetOtpData = () => {
        setotpValue('')
        setotpData({})
        setTimer(0)
    };
    // Handle Otp submit method for signin and signup
    const handleOtpSubmit = (e) => {
        e.preventDefault();
        let ipAddress = JSON.parse(localStorage.getItem("deviceIp"));
        const FCMtoken = JSON.parse(localStorage.getItem('FCMToken'))
        const pincode = JSON.parse(localStorage.getItem("validLocation"));

        let pincodeLocal = '';
        if (pincode) {
            let tmpVar = pincode?.split(" ")
            if (tmpVar)
                pincodeLocal = tmpVar[0]
        }


        dispatch(verifyOTP({ otp: otpValue, phoneNumber: removeMobileFomrmatter(otpData.phoneNumber) }))
            .then(res => {
                if (otpData?.type === 'register') {
                    dispatch(createAcc({ ...otpData, phoneNumber: removeMobileFomrmatter(otpData.phoneNumber), fcmToken: FCMtoken ? FCMtoken : '123455', deviceId: createDeviceId(), deviceType: getDeviceType(), ipAddress: ipAddress || '', pincode: pincodeLocal || '' }))
                        .then(res => {
                            resetOtpData()
                            setotpModal(false)
                            setsignupModal(false)
                            dispatch(getProfile())

                            const localCartData = JSON.parse(localStorage.getItem('cart'))

                            // If local cart has any data then will be added to user cart api
                            if (localCartData && localCartData?.length) {
                                dispatch(addCartLocalToApi({ customerId: res?.data?.id, products: [...localCartData] }))
                                    .then(res2 => {
                                        dispatch(getCart({ customerId: res?.data?.id }))
                                        dispatch(getCartCount({ customerId: res?.data?.id }))
                                        if (localStorage.getItem('cart')) {
                                            localStorage.removeItem('cart')
                                        }
                                        if (localStorage.getItem('cartTotal')) {
                                            localStorage.removeItem('cartTotal')
                                        }
                                    })
                                    .catch(Err => {
                                        dispatch(getCart({ customerId: res?.data?.id }))
                                        dispatch(getCartCount({ customerId: res?.data?.id }))
                                    })
                            }
                            // If local cart has no cart data then will not be added to api
                            else {
                                dispatch(getCart({ customerId: res?.data?.id }))
                                dispatch(getCartCount({ customerId: res?.data?.id }))
                            }
                        })
                        .catch(err => {
                            swal('', err?.message, 'error')
                        })
                }
                else {
                    dispatch(onSignIn({ ...otpData, phoneNumber: removeMobileFomrmatter(otpData.phoneNumber), deviceType: getDeviceType(), deviceId: createDeviceId(), fcmToken: FCMtoken ? FCMtoken : '123455', ipAddress: ipAddress || '', pincode: pincodeLocal || '' }))
                        .then(res => {
                            resetOtpData()
                            setotpModal(false)
                            setsigninModal(false)
                            dispatch(getProfile())

                            const localCartData = JSON.parse(localStorage.getItem('cart'))

                            // If local cart has any data then will be added to user cart api
                            if (localCartData && localCartData?.length) {
                                dispatch(addCartLocalToApi({ customerId: res?.data?.id, products: [...localCartData] }))
                                    .then(res2 => {
                                        dispatch(getCart({ customerId: res?.data?.id }))
                                        dispatch(getCartCount({ customerId: res?.data?.id }))
                                    })
                                    .catch(Err => {
                                        dispatch(getCart({ customerId: res?.data?.id }))
                                        dispatch(getCartCount({ customerId: res?.data?.id }))
                                    })
                            }
                            // If local cart has no cart data then will not be added to api
                            else {
                                dispatch(getCart({ customerId: res?.data?.id }))
                                dispatch(getCartCount({ customerId: res?.data?.id }))
                            }
                        })
                        .catch(err => {
                            console.log('err: ', err);
                            // swal('', err?.message, 'error')
                            // if (err?.message) {
                            //     swal('', err?.message + 'signin', 'error')
                            // }
                        })
                }
            })
            .catch(err => {
                swal('', err.message, 'error')
                // swal('', err?.message + 'outside', 'error')
            })
    };
    // On Resend OTP
    const onResendOtp = () => {
        // setisLoading(true)
        dispatch(sendOTP({ phoneNumber: removeMobileFomrmatter(otpData?.phoneNumber), type: otpData?.type }))
            .then(res => {
                setTimer(60)
                // setisLoading(false)
                swal('', res.message, 'success')
            })
            .catch(err => {
                setTimer(60)
                // setisLoading(false)
                swal('', err.message, 'error')
            })
    };

    const handleRedirect = (path) => {
        if (!isValidLocation) {
            setLocationModal(true)
        } else {
            history.push(path)
        }
    }

    return (
        <>
            {isLoading && <LoaderBox />}
            <header className={`mheader-bar wheader-bar scroll-header ${small ? "" : ""}`}>
                <div className='cls-top-hader'>
                    <div className="container">
                        <div className="row">
                            <div className="d-flex flex-wrap align-items-center w-100 justify-content-between">
                                <div className="left-box"> 
                                        <div className="logo-box">
                                            <Link to="/"><i> <Clefill /> </i></Link>
                                        </div>
                                    <div className="location-box ">
                                        <i> <Iconlocation /> </i>
                                        <p>{authData?.isValidLocation ? removeQuotsFun(authData?.validLocation) : ' No Location '} <Link to="#" onClick={(e) => { e.preventDefault(); setLocationModal(true) }}>&nbsp;{`Change`}</Link></p>
                                    </div>
                                </div>
                                <div className="right-box">
                                    {isLoggedIn && <i onClick={(e) => { e.preventDefault(); history.push('/notifications') }} className={`${notificationCount > 0 ? 'green-dots' : ''}`}> <MdOutlineNotificationsNone /> </i>}
                                    <div className="link-box">
                                        {isLoggedIn && <i className="users" onClick={() => { setshowDropDown(!showDropDown) }}> <AiOutlineUser /> </i>}
                                        {!isLoggedIn ? <>
                                            <Link to="/" onClick={(e) => {
                                                e.preventDefault();
                                                if (!isValidLocation) {
                                                    setLocationModal(true)
                                                }
                                                else {
                                                    setsigninModal(true)
                                                }
                                            }}>Sign in /</Link>
                                            <Link to="/" onClick={(e) => {
                                                e.preventDefault();
                                                if (!isValidLocation) {
                                                    setLocationModal(true)
                                                }
                                                else {
                                                    setsigninModal(true)
                                                }
                                            }}>Register</Link>
                                        </> :
                                            <Link to="" onClick={(e) => { e.preventDefault(); }} style={{ cursor: "default" }}>Hello, {profileData.firstName} {profileData.lastName}</Link>}
                                    </div>

                                    <div className={`drop-down ${showDropDown ? 'active' : ''}`} ref={dropDownRef}>
                                        <ul className="drop-downlist">
                                            {/* {isLoggedIn && <li className="bottom-line"> <NavLink to="/" onClick={(e) => { e.preventDefault() }} className="user" >Hello {profileData.firstName} {profileData.lastName}</NavLink> </li>}
                                        <li> <NavLink to="/" onClick={(e) => { e.preventDefault(); setLocationModal(true) }} className="loction-icon"> {authData?.isValidLocation ? authData?.validLocation : 'No Location '} {` change`} </NavLink> </li> */}
                                            {!isLoggedIn &&
                                                <>
                                                    <li> <NavLink to="/" onClick={(e) => {
                                                        e.preventDefault();
                                                        if (!isValidLocation) {
                                                            setLocationModal(true)
                                                        }
                                                        else {
                                                            setsigninModal(true)
                                                        }
                                                    }}> Sign in </NavLink> </li>
                                                    <li> <NavLink to="/" onClick={(e) => {
                                                        e.preventDefault();
                                                        if (!isValidLocation) {
                                                            setLocationModal(true)
                                                        }
                                                        else {
                                                            setsigninModal(true)
                                                        }
                                                    }}> Register</NavLink> </li>
                                                </>
                                            }
                                            {isLoggedIn &&
                                                <>
                                                    <li> <NavLink to="/account_dashboard/profile" onClick={(e) => { setshowDropDown(false) }}> My Profile </NavLink> </li>
                                                    {/* <li> <NavLink to="/account_dashboard/payment" onClick={(e) => { setshowDropDown(false) }}> My Payment </NavLink> </li> */}
                                                    <li> <NavLink to="/account_dashboard/promo_codes" onClick={(e) => { setshowDropDown(false) }}> My Promo Code </NavLink> </li>
                                                    <li className="bottom-line"> <NavLink to="/account_dashboard/referral_codes" onClick={(e) => { setshowDropDown(false) }}> Refer & Save! </NavLink> </li>
                                                    <li> <NavLink to="/account_dashboard/my_reviews" onClick={(e) => { setshowDropDown(false) }}>My Review</NavLink> </li>
                                                    <li> <NavLink to="/account_dashboard/order_list" onClick={(e) => { setshowDropDown(false) }}> My Orders </NavLink> </li>
                                                    <li> <NavLink to="/account_dashboard/help_center" onClick={(e) => { setshowDropDown(false) }}> Help Center </NavLink> </li>
                                                </>
                                            }
                                            {isLoggedIn && <li> <NavLink to="/" onClick={() => onLogout()}> Log out </NavLink> </li>}
                                        </ul>
                                    </div>
                                    {/* <i onClick={(e) => { e.preventDefault(); history.push('/notifications') }} className="green-dots"> <Notification /> </i> */}
                                    <i onClick={(e) => {
                                        e.preventDefault();
                                        if (isValidLocation)
                                            history.push('/cart')
                                        else
                                            setLocationModal(true)
                                    }} className="cart"> <Cart />  {isLoggedIn ? (cartCount > 0 && <span> {cartCount} </span>) : (cartCountLocal > 0 && <span> {cartCountLocal} </span>)}  {/*<b>5000</b>*/}  </i>
                                </div >
                            </div >
                        </div >
                    </div>
                </div>
                {(history?.location?.pathname === '/' || history?.location?.pathname === "/about_clefill") &&
                    <div className='cls-bootam-hader'>
                        <div className="container">

                            <div className='row align-items-center'>
                                <div className='col-12 col-sm-6 col-md-9 col-lg-10'>
                                <div className='hamburger' onClick={handleClick}>
                                        {click ? (<FaTimes size={20} style={{ color: '#333' }} />) : (<FaBars size={20} style={{ color: '#333' }} />)}
                                    </div>
                                    <div className='menu-main'>
                                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                                            <li className='cls-dropdown-nav'>
                                                <a className='nav-item'>Home Care <RiArrowDownSLine /></a>
                                                <ul className='cls-dropdown-sub'>
                                                    <li>
                                                        <div onClick={() => handleRedirect("/product_list/prefill/dish-wash-gel")} className='nav-item'>
                                                            Dishwash gel
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div onClick={() => handleRedirect("/product_list/prefill/glass-cleaner-")} className='nav-item'>
                                                            Glass cleaner
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div onClick={() => handleRedirect("/product_list/prefill/toilet-cleaner")} className='nav-item'>
                                                            Toilet cleaner
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div onClick={() => handleRedirect("/product_list/prefill/bathroom-cleaner")} className='nav-item'>
                                                            Bathroom cleaner
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div onClick={() => handleRedirect("/product_list/prefill/floor-cleaner")} className='nav-item'>
                                                            Floor cleaner
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a className='nav-item'
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        handleRedirect("/product_list/prefill/laundry-care")
                                                    }}
                                                >Laundry Care</a>
                                            </li>
                                            <li>
                                                <a className='nav-item'
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        handleRedirect("/product_list/prefill/hand-wash")
                                                    }}
                                                >Hand Care</a>
                                            </li>
                                            <li>
                                                <a className='nav-item'
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        handleRedirect("/product_list/refill/all")
                                                    }}
                                                >Refills</a>
                                            </li>
                                            <li>
                                                <a className='nav-item'
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        handleRedirect("/about_clefill")
                                                    }}
                                                >Our Story</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12 col-md-3 col-lg-2'>
                                    <div className='w-search-var-box'>
                                        <input type='text' className='floating__input' value={keyword}
                                            onChange={e => {
                                                e.preventDefault()
                                                if (isValidLocation) {
                                                    setKeyword(e.target.value)
                                                    const value = e.target.value
                                                    if (value?.length > 0) {
                                                        const filteredNames = allProductList.filter((item) => item?.productName.trim().toLowerCase().includes(value.trim().toLowerCase())).splice(0, 5)
                                                        setSuggestions(filteredNames);
                                                    } else {
                                                        setSuggestions([])
                                                    }
                                                }
                                                else {
                                                    setLocationModal(true)
                                                }
                                            }}
                                            onBlur={() => {
                                                // setKeyword()
                                                // setSuggestions([])
                                            }}
                                            placeholder='search product'
                                        />
                                        <button type='button' className='search-btn' ><CgSearch /></button>
                                        <ul className='search-reac'>
                                            {keyword && suggestions.map((item, index) => (
                                                <li key={index} onClick={() => { history.push(`/product_detail/${item?.productcategory.category.toLowerCase()}/${item?.productName.replace(/\s+/g, '-')}`) }}>{item?.productName}</li>
                                            ))}
                                        </ul>
                                        {/* // suggestions.map((item, index) => (<div className='search-reac' key={index}>{item?.productName}</div>)) */}
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                }
            </header >

            {/* otp-model */}
            <div className={`model-box auth-model otp-model ${otpModal ? "model-open" : " "}`}>
                <div className="model-body create-model">
                    <button className="model-close-btn" onClick={() => {
                        setotpModal(false);
                        resetOtpData()
                        // setisAgreed(false)
                    }}> <Close /> </button>
                    <div className="d-flex w-100">
                        <div className="col-6 p-0">
                            <div className="model-right">
                                <figure>
                                    <i> <ProductImg /> </i>
                                </figure>
                            </div>
                        </div>
                        <div className="col-6 p-0">
                            <div className="auth-section otp-section">
                                <div className="auth-header d-flex justify-content-between align-items-center">
                                    <div className="back-arrow" onClick={(e) => {
                                        e.preventDefault();
                                        setotpModal(false);
                                        setotpValue('')
                                        if (otpData?.type === 'register') {
                                            setsignupModal(true)
                                            // setisAgreed(true)
                                        }
                                        else
                                            setsigninModal(true)
                                    }}  > <BackArrow /> </div>
                                </div>

                                <form>
                                    <h1>OTP</h1>
                                    <div className="info-box">
                                        <h6>Please enter Verification code sent to <br /> {`+91 ${displayPhoneNumber(removeCountryCode(otpData?.phoneNumber))} `}
                                            <Link to="/"
                                                className='text-bold'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setotpValue('')
                                                    setotpModal(false);
                                                    if (otpData?.type === 'register') {
                                                        setsignupModal(true)
                                                        // setisAgreed(true)
                                                    }
                                                    else
                                                        setsigninModal(true)
                                                }}>
                                                {` or Change phone number`}
                                            </Link>
                                        </h6>
                                        <div className="otparea d-flex justify-content-center">
                                            <OtpInput
                                                value={otpValue}
                                                onChange={onOtpChange}
                                                isInputNum={true}
                                                numInputs={6}
                                                shouldAutoFocus={true}
                                                className="otp-box"
                                                separator={<span></span>}
                                            />
                                        </div>
                                        {Timer >= 1 && <span className="small-text">Resend in {Timer} seconds</span>}
                                        {Timer < 1 && <button type='button' onClick={onResendOtp} className="links-btn">Resend OTP</button>}
                                        {otpValue.length === 6 && <button type='submit' onClick={handleOtpSubmit} className="links-btn">Submit</button>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* location Modal */}
            <div className={` model-box location-model ${locationModal ? "model-open" : " "}`}>
                <div className="location-box">
                    <button className="model-close-btn" onClick={() => setLocationModal(false)}> <Close /> </button>
                    <h1>Where should we deliver the products?</h1>
                    <p>Please provide your Delivery Location to see products</p>
                    <form>
                        <div className="form-grp">
                            <NumberFormat
                                format={`#   #   #   #   #   #`}
                                onValueChange={(e) => {
                                    if (e.value.length === 6) { onDetectLocation(e.value, 'manual') }
                                }}
                                placeholder={`Type your pincode`}
                                className={`input-box`} />
                            {/* <input type="text" ref={inpRef} className="input-box" placeholder="Type your city or pincode" /> */}
                        </div>
                        <span>or</span>
                        <button type='button' onClick={() => onDetectLocation(false, 'auto')} className="links-btn">Detect my location</button>
                    </form>
                </div>
            </div >

            {/* signup-model */}
            <div className={`model-box auth-model create-model ${signupModal ? "model-open" : " "}`}>
                <Form
                    initialValues={otpData ? { ...otpData, phoneNumber: removeCountryCode(otpData?.phoneNumber) } : {}}
                    onSubmit={(value, formProps) => { onSignUpSubmit(value, formProps) }}
                    validate={CreateAcc}
                >
                    {({ handleSubmit, form }) => (
                        // <form onSubmit={handleSubmit}>
                        <div className="model-body create-model">
                            <button className="model-close-btn" onClick={() => {
                                setsignupModal(false);
                                form.restart();
                                resetOtpData()
                                // setisAgreed(false)
                            }}> <Close /> </button>
                            <div className="d-flex w-100">
                                <div className="col-6 p-0">
                                    <div className="model-right">
                                        <figure>
                                            <i> <ProductImg /> </i>
                                        </figure>
                                    </div>
                                </div>
                                <div className="col-6 p-0">
                                    <div className="auth-section">
                                        <form onSubmit={handleSubmit}>
                                            <div className="info-box">
                                                <h1>Getting Started</h1>
                                                <p>Create a account to Continue !</p>
                                                <ul className="input-list">
                                                    <li>
                                                        <Field
                                                            name="phoneNumber"
                                                            component={renderNumberInput}
                                                            placeholder=' '
                                                            preFix="+91 "
                                                            type="text"
                                                            labelName="Enter your 10 digit mobile number"
                                                            inputClasses="floating__input"
                                                            labelClasses="floating__label"
                                                            containerClasses="floating form-grp"
                                                        />
                                                    </li>
                                                    <li>
                                                        <div className='col-grp p-0'>
                                                            <Field
                                                                name="firstName"
                                                                type="text"
                                                                component={renderInput}
                                                                placeholder=' '
                                                                labelName="First name"
                                                                inputClasses="floating__input"
                                                                labelClasses="floating__label"
                                                                containerClasses="floating form-grp user"
                                                            />
                                                        </div>
                                                        <div className='col-grp p-0'>
                                                            <Field
                                                                name="lastName"
                                                                type="text"
                                                                component={renderInput}
                                                                placeholder=' '
                                                                labelName="Last name"
                                                                inputClasses="floating__input"
                                                                labelClasses="floating__label"
                                                                containerClasses="floating form-grp user"
                                                            />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <Field
                                                            name="email"
                                                            type="email"
                                                            component={renderInput}
                                                            placeholder=' '
                                                            labelName="Email (optional)"
                                                            inputClasses="floating__input"
                                                            labelClasses="floating__label"
                                                            containerClasses="floating form-grp email"
                                                        />
                                                    </li>
                                                    <li className="check-grp check-boxs">
                                                        {/* <input type="checkbox" name="Save" checked={isAgreed} id="Save" onChange={(e) => { setisAgreed(e.target.checked) }} /> */}
                                                        {/* <label htmlFor="Save">By Creating account, you agree to our <Link to="/cms/tc" onClick={(e) => { setsignupModal(false) }}>Terms & Conditions</Link> </label> */}
                                                    </li>
                                                </ul>
                                                <button type='submit' className="links-btn">Sign up</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        // </form>
                    )}
                </Form>
            </div >

            {/* signin-model */}
            <div className={` model-box auth-model ${signinModal ? "model-open" : " "}`}>
                <Form
                    initialValues={otpData ? { ...otpData, phoneNumber: '' } : {}}
                    onSubmit={(value, formProps) => { onSignInSubmit(value, formProps) }}
                    validate={SigninValidate}
                >
                    {({ handleSubmit, form }) => (
                        <div className="model-body">
                            <button className="model-close-btn"
                                onClick={() => {
                                    setsigninModal(false);
                                    form.restart();
                                    resetOtpData()
                                }}> <Close /> </button>
                            <div className="d-flex w-100">
                                <div className="col-6 p-0">
                                    <div className="model-right">
                                        <figure>
                                            <i> <ProductImg /> </i>
                                        </figure>
                                    </div>
                                </div>
                                <div className="col-6 p-0">
                                    <div className="auth-section">
                                        <form onSubmit={handleSubmit}>
                                            <div className="info-box">
                                                <h1>Let’s get you logged in</h1>
                                                <p>If you have an account sign in with Registered phone number</p>
                                                <ul className="input-list">
                                                    <li>
                                                        <Field
                                                            name="phoneNumber"
                                                            component={renderNumberInput}
                                                            placeholder=' '
                                                            preFix="+91 "
                                                            type="text"
                                                            labelName="Enter your 10 digit mobile number"
                                                            inputClasses="floating__input"
                                                            labelClasses="floating__label"
                                                            containerClasses="floating form-grp"
                                                            extraClass='active'
                                                        />
                                                    </li>
                                                </ul>
                                                <button type='submit' className="links-btn">Continue</button>
                                            </div>
                                            <span className="small-info">By continuing, you agree to our
                                                <a href={toAbsoluteUrl(`/cms/tc`)} target={'_blank'} rel="noreferrer"> Terms </a>,
                                                <a href={toAbsoluteUrl(`/cms/rrd`)} target={'_blank'} rel="noreferrer">Refunds </a> and
                                                <a href={toAbsoluteUrl(`/cms/tc`)} target={'_blank'} rel="noreferrer"> Privacy Policy </a>
                                            </span>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                </Form>
            </div >
        </>
    );
}

export default Header;
