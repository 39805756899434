import Geocode from "react-geocode";
import { GET_CART_COUNT_LOCAL_SUCCESS } from '../constants/types';
import store from '../redux/store'
export const toAbsoluteUrl = pathname => (process.env.PUBLIC_URL + pathname)

export const removeMobileFomrmatter = (no, isRemoveCountryCode = false) => {
    if (!no)
        return ''
    if (isRemoveCountryCode)
        return no.replace('+91', '').replace(/[&/\\#,+()$~%.'":*?<>{}-]/g, '').replaceAll(' ', '').trim();
    if (!no?.includes('+') && !isRemoveCountryCode)
        return '+91' + no.replace(/[&/\\#,()$~%.'":*?<>{}-]/g, '').replaceAll(' ', '').trim();
    else
        return no.replace(/[&/\\#,()$~%.'":*?<>{}-]/g, '').replaceAll(' ', '').trim();
}
export const removeCountryCode = no => {
    if (!no)
        return ''
    // return no.replace('+91', '').replace(/[&/\\#,+()$~%.'":*?<>{}-]/g, '').replaceAll(' ', '').trim();
    return no?.replace('+91', '')?.replaceAll(' ', '')?.trim();
}
export const addMobileFomrmatter = no => {
    if (!no)
        return ''
    return ` ${no}`;
}

//  CH002 - changed path for fix site map url
//  Auther -Abhishek
//  Start Date -13-03-2022 & End Date -13-03-2022

// export const encrypt = param => {
//     if (param)
//         return btoa(param)
//     else
//         return ''
// }
// export const decrypt = param => {
//     if (param)
//         return atob(param)
//     else
//         return ''
// }

// end change site map : 13/03/2023

export const orderDateFun = (date) => {
    let mnthArry = ["Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ]
    let tmpDate = new Date(date)
    let tmpString = `${tmpDate?.getDate()} ${mnthArry[tmpDate?.getMonth()]} ${tmpDate?.getFullYear()}`
    return tmpString
}

export const addCartQty = () => {
    let cartArry = JSON.parse(localStorage.getItem('cart'));
    let tmpQTY = 0;

    if (cartArry && cartArry?.length) {
        // eslint-disable-next-line array-callback-return
        cartArry.map(val => { tmpQTY = Number(tmpQTY) + Number(val.quantity) })
    }
    localStorage.setItem('cartTotal', tmpQTY)
    store.dispatch({ type: GET_CART_COUNT_LOCAL_SUCCESS, payload: Number(tmpQTY) })
}

export const displayPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{5})(\d{5})$/);
    if (match) {
        return match[1] + ' ' + match[2];
    }
    return null;
}

export const productImagePath = (image) => {
    return `${process.env.REACT_APP_IMAGE_BASE}products/${image}`
}
export const productRatingImagePath = (image) => {
    return `${process.env.REACT_APP_IMAGE_BASE}productreview/${image}`
}

export const priceFunction = (price) => {
    if (price) {
        let number = Number(price)
        return number.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
        });
    }
    return '₹00.00'
}

//remove prefix of query-param
export const removeQueryParam = (search, prefix) => {
    return search.replace(prefix, '')
}

// To Calculate discount 
export const onFindDiscount = (productInfo, selectedSku) => {
    let tmpSKUObj = productInfo?.skus?.length ?
        // eslint-disable-next-line eqeqeq
        productInfo?.skus?.filter((val) => val.id == selectedSku)[0]
        : {}
    if (tmpSKUObj?.salePrice && tmpSKUObj?.productPrice) {
        return priceFunction(Number(tmpSKUObj?.productPrice) - Number(tmpSKUObj?.salePrice))
    } else {
        return ''
    }
}

// Find Discount
export const getDiscount = (val, discount, qty = 1) => {
    let tmpVal = Number(val);
    let tmpDicount = Number(discount);

    if (tmpDicount > 0)
        return (Number(tmpVal * (discount / 100)) * Number(qty))
    else
        return 0
}

export function loadRazorPay(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;

        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script);

    })
}

export async function pincodeFromLatLng(lat, lng) {
    Geocode.setApiKey(process.env.REACT_APP_GEOCODE_API_KEY);
    Geocode.enableDebug();

    let returnVar = false

    await Geocode.fromLatLng(lat, lng).then(
        (response) => {
            const addressArray = response.results[0].address_components;
            let pincode = ''
            if (addressArray?.length) {
                pincode = addressArray[Number(addressArray.length) - 1]
            }
            returnVar = pincode?.long_name ? pincode?.long_name : false
            Promise.resolve(returnVar)
        },
        (error) => {
            console.error(error);
            returnVar = false
            throw new Error("oops,something went wrong");
        }
    );

    return returnVar
}
export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return "mobile";
    }
    return "web";
};
export function buttonDisplayAsPerStatus(buttonName, status, lastUpdated = 10, paid = true) {
    if (buttonName === 'track') {
        if (status === 'on-the-way' || status === 'return-on-the-way') {
            return true
        }
        else {
            return false
        }
    }
    else if (buttonName === 'return') {
        if (status === 'delivered' && status !== 'cancelled' && lastUpdated < 7) {
            return true
        }
        else {
            return false
        }
    }
    else if (buttonName === 'cancel') {
        if (status === 'pending' && status !== 'cancelled') {
            return true
        }
        else {
            return false
        }
    }
    else if (buttonName === 'review') {
        if (status === 'delivered') {
            return true
        }
        else {
            return false
        }
    }
    else if (buttonName === 'invoice') {
        if (paid) {
            if (status !== 'cancelled') {
                return true
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}
export const removeQuotsFun = (data = '') => {
    if (data) {
        return data.replace(/["']/g, "")
    }
    else {
        return ''
    }
}
export const findCityFromGoogleMap = (data = [], type = 'locality') => {
    if (data) {
        const localityObject = data?.filter((obj) => {
            return obj.types.includes(type);
        })[0];
        const city = localityObject?.long_name;
        return city
    }
    else {
        return ''
    }
}
export const statusBarFunction = (status, isReturned) => {
    if (isReturned) {
        if (status === 'return-requested') return 1
        else if (status === 'return-request-accepted' || status === 'return-request-rejected' || status === 'return-request-approved') return 34
        else if (status === 'return-on-the-way') return 68
        else if (status === 'returned' || status === 'not-delivered') return 100
        else return 1
    }
    else {
        if (status === 'pending' || status === 'cancelled') return 1
        else if (status === 'accepted') return 34
        else if (status === 'on-the-way' || status === 'reached') return 68
        else if (status === 'delivered' || status === 'not-delivered') return 100
        else return 1
    }
}
export async function getIpClient() {
    try {
        return fetch('https://api.ipify.org?format=json')
    } catch (error) {
        return Promise.reject(false);
    }
}
export function createDeviceId(length = 5) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}
export const statusArray = [
    { id: 1, name: 'Out For Delivery', value: 'status:on-the-way' },
    { id: 2, name: 'Pending', value: "status:pending" },
    { id: 3, name: 'Accepted', value: 'status:accepted' },
    { id: 4, name: 'Cancelled', value: "status:cancelled" },
    { id: 5, name: 'Delivered', value: "status:delivered" },
    { id: 6, name: 'Not Delivered', value: "status:not-delivered" },
    { id: 7, name: 'Cash on Delivery', value: 'paymentType:COD' },
    { id: 8, name: 'Online Payment', value: 'paymentType:online' },
    { id: 9, name: 'Return Requested', value: "status:return-requested" },
    { id: 10, name: 'Return Request Accepted', value: "status:return-request-accepted" },
    { id: 11, name: 'Return Request Rejected', value: "status:return-request-rejected" },
    { id: 12, name: 'Returned', value: "status:returned" },
    { id: 13, name: 'Out For Pickup', value: "status:return-on-the-way" },
    { id: 14, name: 'All', value: "all" }
]
export const sortArray = [
    { id: 1, name: 'Newest First' },
    { id: 2, name: 'Oldest First' },
]