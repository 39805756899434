import * as ACTION_TYPES from '../../constants/types';

const initialState = {

  // My order
  myOrderList: [],
  myOrderInfo: { deliveryAddress: {} },
  selectedOrderId: null,

  //Payment Details
  cardList: [],

  //Promo-Code
  promoCode: [],

  // Help-Center
  allFaqByCategory: [],
  allFeedBackUser: [],
  allFeedBackCateggory: [],

  // Notification
  notificationList: [],
  notificationCount: 0,

  message: '',
  error: null,
  referralCode: [],
  referralProduct: [],
  referralDiscount: '',
  isAppliedReferralDiscount: false,
  productRatingList: [],
  productReviewList:[]
}

export const utilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_PROMOCODE_SUCCESS:
      return {
        ...state,
        promoCode: action.payload.data.list ? action.payload.data?.list : [],
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_ALL_PROMOCODE_ERROR:
      return {
        ...state,
        promoCode: [],
      }
    case ACTION_TYPES.GET_MY_ORDER_LIST_SUCCESS:
      return {
        ...state,
        myOrderList: action.payload.data.list ? action.payload.data?.list : [],
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_MY_ORDER_LIST_ERROR:
      return {
        ...state,
        myOrderList: [],
      }
    case ACTION_TYPES.GET_MY_ORDER_INFO_SUCCESS:
      return {
        ...state,
        myOrderInfo: action.payload.data,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_MY_ORDER_INFO_ERROR:
      return {
        ...state,
        myOrderInfo: { deliveryAddress: {} },
      }
    case ACTION_TYPES.GET_CARD_SUCCESS:
      return {
        ...state,
        cardList: action.payload.data.list ? action.payload.data?.list : [],
        error: null
      }
    case ACTION_TYPES.SELECT_ORDER_ID:
      return {
        ...state,
        selectedOrderId: action?.payload,
        error: null
      }
    case ACTION_TYPES.GET_CARD_ERROR:
      return {
        ...state,
        cardList: 0,
      }
    // Help - Center
    case ACTION_TYPES.GET_FEEDBACK_CATEGORY_SUCCESS:
      return {
        ...state,
        allFeedBackCateggory: action.payload.data.list ? action.payload.data?.list : [],
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_FEEDBACK_CATEGORY_ERROR:
      return {
        ...state,
        allFeedBackCateggory: [],
      }
    case ACTION_TYPES.GET_FAQ_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        allFaqByCategory: action.payload.data.list ? action.payload.data?.list : [],
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_FAQ_BY_CATEGORY_ERROR:
      return {
        ...state,
        allFaqByCategory: [],
      }
    case ACTION_TYPES.GET_CUSTOMER_FEEDBACK_SUCCESS:
      return {
        ...state,
        allFeedBackUser: action.payload.data.list ? action.payload.data?.list : [],
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_CUSTOMER_FEEDBACK_ERROR:
      return {
        ...state,
        allFeedBackUser: [],
      }
    case ACTION_TYPES.GET_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        notificationCount: action.payload.data?.count,
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_NOTIFICATION_COUNT_ERROR:
      return {
        ...state,
        notificationCount: 0,
      }
    case ACTION_TYPES.GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notificationList: action.payload.data ? action.payload.data : [],
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.GET_NOTIFICATION_LIST_ERROR:
      return {
        ...state,
        notificationList: [],
      }
    case ACTION_TYPES.CHECK_REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        referralCode: action.payload.data ? action.payload.data : [],
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.CHECK_REFERRAL_CODE_ERROR:
      return {
        ...state,
        referralCode: [],
      }
    case ACTION_TYPES.CHECK_AND_GET_REFERRAL_SUCCESS:
      return {
        ...state,
        referralProduct: action.payload.data ? action.payload.data : [],
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.CHECK_AND_GET_REFERRAL_ERROR:
      return {
        ...state,
        referralProduct: [],
      }
    case ACTION_TYPES.ADD_AND_REMOVE_REFERRAL_CODE:
      return {
        ...state,
        referralDiscount: action.payload.referralDiscount,
        isAppliedReferralDiscount: action.payload.isAppliedReferralDiscount
      }
    case ACTION_TYPES.REMOVE_REFERRAL_CODE:
      return {
        ...state,
        referralCode: [],
        message: "Referral code removed successfully",
        error: null
      }
    case ACTION_TYPES.API_LIST_PRODUCT_RATING_S:
      return {
        ...state,
        productRatingList: action.payload.data ? action.payload.data : [],
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.API_LIST_PRODUCT_RATING_F:
      return {
        ...state,
        productRatingList: [],
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.API_SHOW_LIST_PRODUCT_REVIEW_S:
      return {
        ...state,
        productReviewList: action.payload.data ? action.payload.data.list : [],
        message: action.payload.message,
        error: null
      }
    case ACTION_TYPES.API_SHOW_LIST_PRODUCT_REVIEW_F:
      return {
        ...state,
        productReviewList: [],
        message: action.payload.message,
        error: null
      }
    default:
      return state;


  }
}
