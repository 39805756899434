export const API = 'API'
export const API_REQUEST = 'API_REQUEST'
export const API_SUCCESS = 'API_SUCCESS'
export const API_ERROR = 'API_ERROR'

export const LOADER_STATUS_CHANGE = 'LOADER_STATUS_CHANGE'

export const SET_OTP_DATA = 'SET_OTP_DATA'

//LOGIN Types
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_FAILED = "AUTHENTICATE_FAILED";

//LOGOUT Types
export const LOGOUT = "LOGOUT"

//PROFILE Types
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_ERROR = "FETCH_PROFILE_ERROR";

//ADDRESS Types
export const FETCH_ADDRESS_SUCCESS = "FETCH_ADDRESS_SUCCESS";
export const FETCH_ADDRESS_ERROR = "FETCH_ADDRESS_ERROR";

//ADDRESS Types
export const SET_EDIT_ADDRESS_SUCCESS = "SET_EDIT_ADDRESS_SUCCESS";

//Check Location Types
export const VALID_LOCATION_SUCCESS = "VALID_LOCATION_SUCCESS";
export const VALID_LOCATION_ERROR = "VALID_LOCATION_ERROR";

//ACTIVE PINCODE
export const GET_ACTIVE_AREA_LIST_S = "GET_ACTIVE_AREA_LIST_S";
export const GET_ACTIVE_AREA_LIST_F = "GET_ACTIVE_AREA_LIST_F";

// LOCATION FROM PINCODE
export const FETCH_LOCATION_FRM_PINCODE_SUCCESS = "FETCH_LOCATION_FRM_PINCODE_SUCCESS";

//GET CMS Types
export const GET_CMS_SUCCESS = "GET_CMS_SUCCESS";
export const GET_CMS_ERROR = "GET_CMS_ERROR";

// ---------------
// Product Types
// ---------------

//PRODUCT LIST API
export const PROD_LIST_SUCCESS = "PROD_LIST_SUCCESS";
export const PROD_LIST_ERROR = "PROD_LIST_ERROR";

export const PRODUCT_LIST_LIMIT_MOBILE = "PRODUCT_LIST_LIMIT_MOBILE";

export const PRODUCT_LIST_LIMIT_WEB = "PRODUCT_LIST_LIMIT_WEB";

//PRODUCT DETAILS
export const PROD_DETAIL_SUCCESS = "PROD_DETAIL_SUCCESS";
export const PROD_DETAIL_ERROR = "PROD_DETAIL_ERROR";

//PRODUCT Type API
export const PROD_TYPE_SUCCESS = "PROD_TYPE_SUCCESS";
export const PROD_TYPE_ERROR = "PROD_TYPE_ERROR";

//PRODUCT CATEGORY API
export const PROD_CATEGORY_SUCCESS = "PROD_CATEGORY_SUCCESS";
export const PROD_CATEGORY_ERROR = "PROD_CATEGORY_ERROR";

//CART LIST API
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_ERROR = "GET_CART_ERROR";

//CART COUNT API
export const GET_CART_COUNT_SUCCESS = "GET_CART_COUNT_SUCCESS";
export const GET_CART_COUNT_ERROR = "GET_CART_COUNT_ERROR";

//CART COUNT LOCAL API
export const GET_CART_COUNT_LOCAL_SUCCESS = "GET_CART_COUNT_LOCAL_SUCCESS";
export const GET_CART_COUNT_LOCAL_ERROR = "GET_CART_COUNT_LOCAL_ERROR";

// Cart Total 
export const ADD_CART_TOTAL_SUCCESS = "ADD_CART_TOTAL_SUCCESS";

// Cart DISCOUNT 
export const ADD_CART_DISCOUNT_SUCCESS = "ADD_CART_DISCOUNT_SUCCESS";

// Cart SUB_TOAL 
export const ADD_CART_SUB_TOAL_SUCCESS = "ADD_CART_SUB_TOAL_SUCCESS";

// Cart Redirection 
export const SHLD_REDIRECT_TO_CART = "SHLD_REDIRECT_TO_CART";

// ---------------
// User Utils Types
// ---------------

export const GET_ALL_PROMOCODE_SUCCESS = "GET_ALL_PROMOCODE_SUCCESS";
export const GET_ALL_PROMOCODE_ERROR = "GET_ALL_PROMOCODE_ERROR";

//CARD LIST API
export const GET_CARD_SUCCESS = "GET_CARD_SUCCESS";
export const GET_CARD_ERROR = "GET_CARD_ERROR";

//MY_ORDER_LIST  API
export const GET_MY_ORDER_LIST_SUCCESS = "GET_MY_ORDER_LIST_SUCCESS";
export const GET_MY_ORDER_LIST_ERROR = "GET_MY_ORDER_LIST_ERROR";

// Select Order Id
export const SELECT_ORDER_ID = "SELECT_ORDER_ID";

//MY_ORDER_INFO  API
export const GET_MY_ORDER_INFO_SUCCESS = "GET_MY_ORDER_INFO_SUCCESS";
export const GET_MY_ORDER_INFO_ERROR = "GET_MY_ORDER_INFO_ERROR";

// HELP CENTER

// GET ALL FEEDBACK
export const GET_FAQ_BY_CATEGORY_SUCCESS = "GET_FAQ_BY_CATEGORY_SUCCESS";
export const GET_FAQ_BY_CATEGORY_ERROR = "GET_FAQ_BY_CATEGORY_ERROR";

// GET ALL FEEDBACK OF CUSTOMER
export const GET_CUSTOMER_FEEDBACK_SUCCESS = "GET_CUSTOMER_FEEDBACK_SUCCESS";
export const GET_CUSTOMER_FEEDBACK_ERROR = "GET_CUSTOMER_FEEDBACK_ERROR";

// GET ALL FEEDBACK CATEGORY
export const GET_FEEDBACK_CATEGORY_SUCCESS = "GET_FEEDBACK_CATEGORY_SUCCESS";
export const GET_FEEDBACK_CATEGORY_ERROR = "GET_FEEDBACK_CATEGORY_ERROR";

// NOTIFICATION

//Get all notification
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_ERROR = "GET_NOTIFICATION_LIST_ERROR";

//Get notification COUNT
export const GET_NOTIFICATION_COUNT_SUCCESS = "GET_NOTIFICATION_COUNT_SUCCESS";
export const GET_NOTIFICATION_COUNT_ERROR = "GET_NOTIFICATION_COUNT_ERROR";


//Referal Code
export const CHECK_AND_GET_REFERRAL_SUCCESS="CHECK_AND_GET_REFERRAL_SUCCESS";
export const CHECK_AND_GET_REFERRAL_ERROR= "CHECK_AND_GET_REFERRAL_ERROR";
export const CHECK_REFERRAL_CODE_SUCCESS="CHECK_REFERRAL_CODE_SUCCESS";
export const CHECK_REFERRAL_CODE_ERROR= "CHECK_REFERRAL_CODE_ERROR";
export const REMOVE_REFERRAL_CODE= "REMOVE_REFERRAL_CODE";
export const ADD_AND_REMOVE_REFERRAL_CODE= "ADD_AND_REMOVE_REFERRAL_CODE";
export const API_GET_REFERRAL_DATA_SUCCESS= "API_GET_REFERRAL_DATA_SUCCESS";
export const API_GET_REFERRAL_DATA_ERROR = "API_GET_REFERRAL_DATA_ERROR";
export const API_GET_REFERRAL_COUPON_DATA_SUCCESS="API_GET_REFERRAL_COUPON_DATA_SUCCESS";
export const API_GET_REFERRAL_COUPON_DATA_ERROR="API_GET_REFERRAL_COUPON_DATA_ERROR";

//Product Rating
export const API_PRODUCT_STAR_RATING_S="API_PRODUCT_STAR_RATING_S";
export const API_PRODUCT_STAR_RATING_F="API_PRODUCT_STAR_RATING_F";

export const API_ADD_PRODUCT_REVIEW_S="API_ADD_PRODUCT_REVIEW_S";
export const API_ADD_PRODUCT_REVIEW_F="API_ADD_PRODUCT_REVIEW_F";

export const API_LIST_PRODUCT_RATING_S="API_LIST_PRODUCT_RATING_S";
export const API_LIST_PRODUCT_RATING_F="API_LIST_PRODUCT_RATING_F";

export const API_SHOW_LIST_PRODUCT_REVIEW_S="API_SHOW_LIST_PRODUCT_REVIEW_S";
export const API_SHOW_LIST_PRODUCT_REVIEW_F="API_SHOW_LIST_PRODUCT_REVIEW_F";









