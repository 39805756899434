export const { REACT_APP_API_BASE: API_BASE } = process.env

//Auth Api 
export const API_LOGIN = `${API_BASE}auth/customer/login`

//Logout api
export const API_LOGOUT = `${API_BASE}api/customer/logout`

//Send OTP Api 
export const API_SEND_OTP = `${API_BASE}auth/customer/sendOtpToUser`

//Verify OTP Api 
export const API_VERIFY_OTP = `${API_BASE}auth/customer/verifyOtp`

//Create Account 
export const API_CREATE_ACC = `${API_BASE}auth/customer/register`

//Check Location
export const API_CHECK_LOCATION = `${API_BASE}auth/customer/checkServiceableableArea`

// Active servicable area
export const API_GET_ACITVE_AREA_LIST = `${API_BASE}api/admin/getActiveAreaList`;

//Location name from pincode
export const API_LOCATION_FRM_PIN = `https://api.postalpincode.in/pincode/`

//Get User Profile
export const API_GET_USER_PROFILE = `${API_BASE}api/customer/getProfile`

//Update User Profile
export const API_UPDATE_USER_PROFILE = `${API_BASE}api/customer/updateProfile`

//Get User Addresses
export const API_GET_ALL_ADDRESS = `${API_BASE}api/customer/listAddress`

//ADD User Addresses
export const API_ADD_USER_ADDRESS = `${API_BASE}api/customer/addAddress`

//Update User Addresses
export const API_UPDATE_USER_ADDRESS = `${API_BASE}api/customer/updateAddress`

//Delete User Addresses
export const API_DELETE_USER_ADDRESS = `${API_BASE}api/customer/deleteAddress`

//Select User Addresses as Default
export const API_SELECT_USER_ADDRESS_DEFAULT = `${API_BASE}api/customer/setDefaultAddress`

//Get CMS detail
export const API_GET_CMS_API = `${API_BASE}auth/customer/getCms`


//Product Apis

// Product list

export const API_LIST_PRODUCT = `${API_BASE}auth/customer/listProducts`

//coupon List
export const SHOW_COUPON_LIST = `${API_BASE}auth/customer/showCoupon`

export const VISITOR_CALL = `${API_BASE}auth/customer/visitorCall`

// Product details
export const API_DETAILS_PRODUCT = `${API_BASE}auth/customer/getProductDetails`

// Product Category
export const API_GET_PROD_CAT = `${API_BASE}auth/customer/listCategory`

// Product TYPE
export const API_GET_PROD_TYPE = `${API_BASE}auth/customer/listProductType`

//Cart APis
//Add To Cart
export const API_ADD_TO_CART = `${API_BASE}api/customer/addItemToCart`

//Get Cart
export const API_GET_CART = `${API_BASE}api/customer/getCartItem`

//Get Cart Count
export const API_GET_CART_COUNT = `${API_BASE}api/customer/getUserCartCount`

//REMOVE PRODUCT FROM Cart
export const API_REMOVE_PROD_FROM_CART = `${API_BASE}api/customer/removeProductFrmCart`

//UPDATE PRODUCT QTY
export const API_UPDATE_PROD_QTY = `${API_BASE}api/customer/updateProductQty`

//EMPTY CART
export const API_EMPTY_CART = `${API_BASE}api/customer/emptyUsersCart`

//ADD CART LOCAL TO API
export const API_CART_LOCAL_TO_API = `${API_BASE}api/customer/addLocalItemToCart`

//Get CARD
export const API_GET_CARD_LIST = `${API_BASE}api/customer/getCardList`

//Add CARD
export const API_ADD_CARD = `${API_BASE}api/customer/addCard`

//Edit CARD
export const API_EDIT_CARD = `${API_BASE}api/customer/editCard`

//Delete CARD
export const API_DELETE_CARD = `${API_BASE}api/customer/deleteCard`

//Get All Promocode
export const API_GET_ALL_PROMOCODE = `${API_BASE}api/customer/listAllCoupon`



//Verify Prmocode
export const API_VERIFY_PROMOCDE = `${API_BASE}api/customer/`

//Apply Prmocode
export const API_APPLY_PROMOCDE = `${API_BASE}api/customer/applyCoupon`

//Remove Prmocode
export const API_REMOVE_PROMOCDE = `${API_BASE}api/customer/removeAppliedCoupon`

//Get All order
export const API_GET_ALL_ORDER = `${API_BASE}api/customer/getOrderList`

//Get order info
export const API_GET_ORDER_INFO = `${API_BASE}api/customer/getOrderDetails`

//Place order 
export const API_PLACE_ORDER = `${API_BASE}api/customer/placeOrder`

//Return order 
export const API_RETURN_ORDER = `${API_BASE}api/customer/returnOrder`

//Cancel order 
export const API_CANCEL_ORDER = `${API_BASE}api/customer/cancelOrder`

//Pay Online order 
export const API_PAY_ONLINE_ORDER = `${API_BASE}api/customer/payOnline`

//Generate Payment link
export const API_VERIFY_TOKEN = `${API_BASE}auth/customer/validPaymentToken`

//Generate Payment link
export const API_COMPLETE_COD_PAYMENT = `${API_BASE}auth/customer/codPaymentVerify`


//Tracking

// Find Distance Between Two Location
export const API_GET_LOCATION_BTWN_TWO = `https://maps.googleapis.com/maps/api/directions/json`

// Help Center

// Get all feedback of user
export const API_GET_ALL_FEEDBACK_USER = `${API_BASE}api/customer/allFeedbackOfUser`

// Get all feedback of user
export const API_ADD_FEEDBACK = `${API_BASE}api/customer/addFeedback`

// Get all FAQ category
export const API_ALL_FAQ_CATEGORY = `${API_BASE}api/customer/allFaqCategory`

// Get all faq by category
export const API_GET_FAQ_BY_CATEGORY = `${API_BASE}api/customer/getFaqByCategory`

// Get all faq by category
export const API_GET_FAQ_LIST = `${API_BASE}api/customer/allFaq`

//Notification Module 
export const API_NOTIFICATION_COUNT = `${API_BASE}api/customer/unreadNotification`

export const API_NOTIFICATION_LIST = `${API_BASE}api/customer/getNotificationList`

export const API_READ_NOTIFICATION = `${API_BASE}api/customer/readNotification`

export const API_DELETE_NOTIFICATION = `${API_BASE}api/customer/deleteNotification`

export const API_DOWNLOAD_INVOICE = `${API_BASE}api/customer/genrateInvoice`

//Abhishek Srivastava
export const API_GET_PROMO_CODE_USED=`${API_BASE}api/admin/getCouponUsed`;

//Referral Code 
export const API_GET_CUSTOMER_ORDER_COUNT=`${API_BASE}api/customer/getCustomerOrderCount`;
export const API_CHECK_AND_GET_REFERRAL=`${API_BASE}api/customer/checkAndGetReferral`;
export const API_CHECK_REFERRAL_CODE=`${API_BASE}api/customer/checkReferralCode`;
export const API_GET_REFERRAL_DATA=`${API_BASE}api/customer/getReferralData`;
export const API_GET_REFERRAL_COUPON_DATA=`${API_BASE}api/customer/getReferralCoupon`;

//Product Rating
export const API_PRODUCT_STAR_RATING=`${API_BASE}api/customer/productStarRating`;
export const API_ADD_PRODUCT_REVIEW=`${API_BASE}api/customer/addProductReview`;
export const API_LIST_PRODUCT_RATING=`${API_BASE}auth/customer/getListProductRating`;
export const API_SHOW_LIST_PRODUCT_REVIEW=`${API_BASE}api/customer/showListProductReview`;





